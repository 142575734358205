/**
 * Author's Note:
 * We are migrating to a Stand Alone mutation for Sponsored Recommendation,
 * which can be found in the composable useSendBidId.
 * Whilst we migrate across, we will support the old useSponsoredRec in some of the
 * older query types. But we will save the results in useSendidId's Record<>.
 */
import { computed, provide } from 'vue';
import { MaybeRefOrGetter, toValue } from '@vueuse/core';

import { SessionEnvironmentContextInput, SponsoredRecommendationsInput, SraPlacement } from '@/@types/graphql-types';
import type { SponsoredAdFragment } from '@/pages/graphql/fragments/SponsoredAd.fragment';

import { saveBidIdPlacement, SR_PROVIDER_KEY, SRPageTypes } from '@/components/sponsored-recommendations/useSendBidId';
import { ObjectType, SraFormat } from '@/@types/graphql-types';
import { Platform } from '@/@types/graphql-types';
import { TrackingAppId, ApplicationContext } from '@/helpers/tracking/tracking-helper';
import { useLanguageStore, useTrackingStore } from '@/helpers/composables/useStores';
import { getSrDebuggerInfo } from '@/helpers/sponsoredrec-debugging-tools';
import type { SessionEnvironment } from '@/stores/tracking.store';

interface UseSponsoredRecOptions {
	pageType: MaybeRefOrGetter<SRPageTypes>;
	placement: MaybeRefOrGetter<SraPlacement>;
	testingMode?: boolean;
	alwaysReturnBidID?: boolean;
}

export const supportedFormats = [SraFormat.Image, SraFormat.Video];
export const supportedObjectTypes = [
	ObjectType.Movie,
	ObjectType.Show,
	ObjectType.GenericTitleList,
	ObjectType.ShowSeason,
];

export function useSponsoredRec({
	pageType,
	placement,
	testingMode = false,
	alwaysReturnBidID = true,
}: UseSponsoredRecOptions) {
	const trackingStore = useTrackingStore();
	const { language, country } = useLanguageStore();

	// We need to support both versions whilst we migrate to the
	provide(SR_PROVIDER_KEY, `${toValue(placement)}-${toValue(pageType)}`);

	// Get the debugging information to override campaign settings
	const srDebuggerInfo = getSrDebuggerInfo();

	const sessionEnvironmentContext = computed<SessionEnvironmentContextInput>(() => {
		const {
			// fields not in mutation
			sessionReady,
			originRefrSocial,

			// fields to rename
			hasAdBlocker: hasAdblocker,
			originPageUrl: originPageURL,

			...sessionEnvironment
		} = trackingStore.sessionEnvironment.value as SessionEnvironment;

		void sessionReady;
		void originRefrSocial;

		return { ...sessionEnvironment, hasAdblocker, originPageURL };
	});

	const allowSponsoredRecommendations = computed<SponsoredRecommendationsInput>(() => ({
		// Campaign specific information
		pageType: toValue(pageType),
		placement: toValue(placement),

		// Default settings
		language: language.value,
		country: country.value,
		applicationContext: ApplicationContext, // now with appContext
		appId: TrackingAppId,
		platform: Platform.Web,
		sessionEnvironmentContext: sessionEnvironmentContext.value,

		// Have every SR Placement supoort every format
		supportedFormats,
		supportedObjectTypes,
		alwaysReturnBidID,

		// Debugging information
		geoCountry: srDebuggerInfo?.geoCountry,
		testingModeCampaignName: srDebuggerInfo?.campaignName,
		testingModeSubcampaignId: srDebuggerInfo?.subcampaignId,
		testingModeForceHoldoutGroup: !!srDebuggerInfo?.holdoutGroup,
		testingMode: srDebuggerInfo?.testingMode || testingMode,
	}));

	return {
		/** Input values to call Sponsored Recommmendation */
		allowSponsoredRecommendations,

		/** Save the campaign and it's placement to the new Send Bid ID */
		saveSrPlacement: (sponsoredAd: SponsoredAdFragment | undefined) => {
			if (!sponsoredAd) return;

			saveBidIdPlacement({
				pageType: () => toValue(pageType),
				placement: () => toValue(placement),
				ad: () => sponsoredAd,
			});
		},
	};
}
